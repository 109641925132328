import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WorkstationCanvas from "Components/Workstation/WorkstationCanvas/WorkstationCanvas.component";
import { FileDownloadModal, SyncClipboardModal } from "Components/Workstation/Modals";
import WebsocketConnection from "Utils/Workstation/WebsocketConnection";
import LinuxWebsocketConnection from "Utils/Workstation/LinuxWebsocketConnection";
import {
  REQUEST_STATUS,
  LOCAL_STORAGE,
  STREAM_TYPE,
  KEY_MAPPING_OPTIONS,
  SESSION_STORAGE,
  OS,
  WORKSTATION_FRIENDLY_STATUS,
  INTERNAL_SDK_MESSAGES,
} from "Constants/global.constants";

import {
  BIT_RATES,
  ERROR_CODE,
  ERRORS,
  DOCK_VISIBILITY,
  SOUND_AND_MICROPHONE_OPTIONS,
  JOYSTICK_OPTIONS,
  DOCK_POSITIONS,
  REGENERATE_APP_SESSION_ID_PARAM,
} from "Constants/AppStreaming.constants";
import { getAppStreamingSessionAPI, resetAppStreamingSessionCtx } from "Actions/Dashboard/Workstations.actions";
import { uploadStreamSessionFile, downloadFileAPISuccess, clearUploadedFiles } from "Actions/Dashboard/Files.actions";
import { getItemFromLocalStorage, Logger, saveItemToLocalStorage, Tracker } from "Utils";
import {
  getItemFromSessionStorage,
  removeItemFromLocalStorage,
  saveItemToSessionStorage,
} from "Utils/Helpers/storage.helpers";
import KeyboardShortcuts from "Components/Workstation/WorkstationDock/KeyboardShortcuts/KeyboardShortcuts.component";
import routes from "Constants/Route.constants";
import IframeModal from "Components/Workstation/Modals/IframeModal/IframeModal.component";
import { Modal } from "UI";
import { applyAdvancedStreamingSettings, applyScrollDirectionPreference } from "Utils/Helpers/streaming.helpers";
import { isMobile, getClientInfo, getDeviceType, isTablet, isIPad, isSafari } from "Utils/Helpers/browser.helpers";
import { useHotkeys } from "react-hotkeys-hook";
import AppStreamingDock from "Components/AppStreaming/AppStreamingDock/AppStreamingDock.component";
import WorkstationCursor from "Components/Workstation/WorkstationCursor/WorkstationCursor.component";
import AppStreamingLoader from "Components/AppStreaming/AppStreamingLoader/AppStreamingLoader.component";
import {
  ToastNotificationContainer,
  ToastNotificationContent,
} from "Components/Workstation/ToastNotification/ToastNotification";
import { API_ENDPOINTS } from "Constants/api.constants";
import apiGenerator from "Utils/Helpers/api.helpers";
import { pingRegion } from "Utils/Helpers/pinger.helpers";
import IdleTracker from "Utils/Components/IdleTracker/IdleTracker.component";
import FilesDock from "Components/AppStreaming/FilesDock/FilesDock";
import FirstClickHandlerWrapper from "Utils/Components/FirstClickHandler";
import TempFileUploadOverlay from "Components/AppStreaming/FileUploadOverlay/TempFileUploadOverlay";
import SDKInternal from "Utils/Workstation/SDKInternal";
import KeyboardButton from "Components/Workstation/WorkstationDock/KeyboardButton/KeyboardButton.component";
import Joystick from "./Joystick/Joystick.component";

const POLLING_INTERVAL = 20000;
const POLLING_INTERVAL_DENSE = 3000;
const POLLING_MAX_FAILURE_COUNT = 2;
const RESOLUTIONS = {
  res_720p: { x: 1280, y: 720 },
  res_1080p: { x: 1920, y: 1080 },
  res_2160p: { x: 3840, y: 2160 },
};

const IDLE_TIME_WARNING_OFFSET = 20000;

export function Hotkey({ hotkey, callback }) {
  useHotkeys(hotkey, callback);
  return null;
}

class AppStreamingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      showKeyboardShortcuts: false,
      showClipboardModal: false,
      clipboardModalCopyMode: true,
      micEnabled: false,
      soundEnabled: false,
      changeableSound: true,
      dockVisibility: DOCK_VISIBILITY.always_visible,
      dockColor: "",
      iframeSrc: null,
      showConnectionModal: false,
      launchFlags: "",
      gameModeEnabled: false,
      initialGameMode: false,
      gameModeDisabledFromSDK: false,
      changeableKeyMapping: true,
      quality: getItemFromLocalStorage(LOCAL_STORAGE.appStreamingQualitySelection, ""),
      changeableQuality: true,
      isDockOpen: false,
      clickCount: 0,
      willEnableGameMode: false,
      displayDockButton: true,
      cursorX: 0,
      cursorY: 0,
      cursorImage: "",
      maxSessionDuration: null,
      sessionExpireTimeout: null,
      forceSoundEnabled: false,
      clickToStart: false,
      isFilesDockOpen: false,
      tempFiles: [],
      showDownloadModal: false,
      downloadFailed: false,
      showKeyboardButton: false,
      gameModeDisabledNotificationShown: false,
      resetIdleTracker: false,
      metrics: {},
      streamID: null,
      enterprise: false,
    };
  }

  componentDidMount() {
    const { workstations } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const newSession = urlParams.get(REGENERATE_APP_SESSION_ID_PARAM);

    if (newSession) {
      removeItemFromLocalStorage(LOCAL_STORAGE.appSessionId);
    }

    this.videoElement = document.getElementById("player");
    this.setPollingInterval(POLLING_INTERVAL_DENSE);

    this.setState({
      launchFlags: urlParams.get("launchFlags"),
    });

    if (workstations.getAppStreamingSessionCTX.status !== REQUEST_STATUS.SUCCESS) {
      this.controlStreamingStatus();
    }

    /* Firefox */
    document.addEventListener("mozfullscreenchange", this.handleResize);

    /* Chrome, Safari and Opera */
    document.addEventListener("webkitfullscreenchange", this.handleResize);

    document.addEventListener("click", this.hideDockButton); // hide dock button after first left or right click

    if (this.videoElement) {
      this.videoElement.addEventListener("click", this.checkGameModeStatus); // init game mode after first left or right click
    }

    document.addEventListener("contextmenu", this.hideDockButton);

    document.addEventListener("pointerlockerror", this.handlePointerLockError, false);

    document.addEventListener("pointerlockchange", this.onPointerLockChanged, false);

    if (isSafari) {
      window.addEventListener("orientationchange", this.onOrientationChange);
    } else if (window.screen.orientation) {
      window.screen.orientation.addEventListener("change", this.onOrientationChange);
    }
  }

  componentDidUpdate(prevProps) {
    const { workstations, history } = this.props;
    const { connected, maxSessionDuration, sessionExpireTimeout, enterprise } = this.state;

    if (
      prevProps.workstations.getAppStreamingSessionCTX.data?.attributes?.friendly_status !==
        workstations.getAppStreamingSessionCTX.data?.attributes?.friendly_status &&
      workstations.getAppStreamingSessionCTX.data?.attributes?.application?.attributes?.enterprise
    ) {
      const { friendly_status: friendlyStatus } = workstations.getAppStreamingSessionCTX.data?.attributes || {};
      switch (friendlyStatus) {
        case WORKSTATION_FRIENDLY_STATUS.TURNING_ON:
          SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onInit);
          break;
        case WORKSTATION_FRIENDLY_STATUS.INSTALLING:
          SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onInstalling);
          break;
        case WORKSTATION_FRIENDLY_STATUS.PREPARING_ASSETS:
          SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onPreparingAssets);
          break;
        default:
          break;
      }
    }

    if (
      !connected &&
      ((prevProps.workstations.getAppStreamingSessionCTX.status !== REQUEST_STATUS.SUCCESS &&
        workstations.getAppStreamingSessionCTX.status === REQUEST_STATUS.SUCCESS) ||
        (prevProps.workstations.getAppStreamingSessionCTX.data?.attributes?.friendly_status !== "ready" &&
          workstations.getAppStreamingSessionCTX.data?.attributes?.friendly_status === "ready"))
    ) {
      const {
        connection_uri: connectionUri,
        friendly_status: friendlyStatus,
        key_mapping_selection: keyMappingSelection,
        changeable_key_mapping: changeableKeyMapping,
        quality,
        changeable_quality: changeableQuality,
        dock_visibility: dockVisibility,
        dock_color: dockColor,
        microphone,
        sound,
        region,
        pixel_streaming_enabled: pixelStreamingEnabled,
        render_streaming_enabled: renderStreamingEnabled,
        os,
        active_stream_id: activeStreamId,
        fps,
        application,
      } = workstations.getAppStreamingSessionCTX.data?.attributes || {};

      this.setState({ streamID: activeStreamId, enterprise: application?.attributes?.enterprise });

      this.setSessionMetrics(region);

      if (pixelStreamingEnabled && friendlyStatus === "ready") {
        const { match } = this.props;
        const sessionId = match.params.uri;
        history.push(match.url.replace(sessionId, `${sessionId}/pixelstreaming`));
      }

      if (renderStreamingEnabled && friendlyStatus === "ready") {
        const { match } = this.props;
        const sessionId = match.params.uri;
        history.push(match.url.replace(sessionId, `${sessionId}/renderstreaming`));
      }

      Logger.log("CONNECTION - Status", connectionUri, friendlyStatus, os);

      if (friendlyStatus === "ready" && (os === OS.windows || (os === OS.linux && connectionUri))) {
        const urlParams = new URLSearchParams(window.location.search);
        const bitrate =
          urlParams.get("bitrate") ||
          getItemFromLocalStorage(LOCAL_STORAGE.appStreamingSettings)?.bitrate ||
          BIT_RATES[quality] ||
          4000000;
        this.applyStreamingPreferences({ bitrate, fps });

        const streamLocalSoundState = getItemFromSessionStorage(SESSION_STORAGE.soundEnabled, false);

        if (
          microphone !== SOUND_AND_MICROPHONE_OPTIONS.off || // to show input selection if mic enabled
          sound === SOUND_AND_MICROPHONE_OPTIONS.activate_on_start || // interaction needed to enable sound
          keyMappingSelection !== KEY_MAPPING_OPTIONS.CLICK || // interaction needed to enable game mode
          streamLocalSoundState // previous state of sound, same as sound === SOUND_AND_MICROPHONE_OPTIONS.on
        ) {
          this.setClickToStart(true);
        }

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          {
            quality,
            changeableQuality,
            changeableKeyMapping,
            dockVisibility,
            dockColor,
            soundEnabled: streamLocalSoundState,
            initialGameMode: keyMappingSelection === KEY_MAPPING_OPTIONS[360],
          },
          () => {
            this.initializeSocket();
          },
        );
        // this.initializeSocket();
        this.setPollingInterval(POLLING_INTERVAL);
        // if (keyMappingSelection === KEY_MAPPING_OPTIONS[360]) {
        //   this.setGameModePollingInterval();
        // }
      }
    }

    const { data: sessionData, failureCount } = workstations.getAppStreamingSessionCTX;
    const { client_code: clientCode } = sessionData || {};

    const {
      maximum_session_duration: maxDuration,
      active_session_start_at: startAt,
      maximum_session_duration_enabled: maxSessionDurationEnabled,
    } = sessionData.attributes || {};

    const { translate } = this.props;

    if (maxSessionDurationEnabled && startAt && maxSessionDuration !== maxDuration) {
      const estimatedRemainingSeconds = new Date(startAt).getTime() + maxDuration * 60 * 1000 - new Date().getTime();

      const timeout = setTimeout(() => {
        this.showToastNotification({
          description: translate("sessionExpireMessage"),
          autoClose: 50000,
        });
      }, estimatedRemainingSeconds - 60000);
      if (sessionExpireTimeout) {
        clearTimeout(sessionExpireTimeout);
      }
      this.setSessionExpireTimeout(timeout);
      this.setMaxSessionDuration(maxDuration);
    }

    if (
      workstations.getAppStreamingSessionCTX.status === REQUEST_STATUS.FAILURE ||
      workstations.getAppStreamingSessionCTX.data?.attributes?.connection_status === "proxy_rule_migration"
    ) {
      if (clientCode === 700 && failureCount < POLLING_MAX_FAILURE_COUNT) {
        return;
      }

      if (clientCode === ERROR_CODE.INSTALLATION_FAILED) {
        if (enterprise) {
          SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onInstallationFailed);
        }

        this.sendParentMessageAndRedirect(ERRORS.INSTALLATION_FAILED);

        return;
      }

      if (clientCode === ERROR_CODE.SESSION_EXPIRED) {
        if (enterprise) {
          SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onSessionExpired);
        }

        this.sendParentMessageAndRedirect(ERRORS.SESSION_EXPIRED);

        return;
      }

      if (enterprise) {
        SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onFailed);
      }

      this.sendParentMessageAndRedirect(ERRORS.STREAM_NOT_FOUND);
    }
  }

  componentWillUnmount() {
    const { sessionExpireTimeout } = this.state;
    const { resetAppStreamingSessionCtx } = this.props;
    Logger.log("Workstation|ComponentWillUnmount");
    if (this.websocketConnection) {
      // Override onclose handler and don't reinit socket.
      this.websocketConnection.closeSockets();
      this.websocketConnection.stopStreaming();
    }

    clearInterval(this.pollingInterval);

    /* Firefox */
    document.removeEventListener("mozfullscreenchange", this.resizeWindow);
    /* Chrome, Safari and Opera */
    document.removeEventListener("webkitfullscreenchange", this.resizeWindow);

    document.removeEventListener("click", this.hideDockButton);
    document.removeEventListener("contextmenu", this.hideDockButton);

    document.removeEventListener("pointerlockerror", this.handlePointerLockError, false);
    document.removeEventListener("pointerlockchange", this.onPointerLockChanged, false);

    if (this.videoElement) {
      this.videoElement.removeEventListener("click", this.checkGameModeStatus);
    }

    if (isSafari) {
      window.removeEventListener("orientationchange", this.onOrientationChange);
    } else if (window.screen.orientation) {
      window.screen.orientation.removeEventListener("change", this.onOrientationChange);
    }

    clearTimeout(sessionExpireTimeout);
    resetAppStreamingSessionCtx();
  }

  initGameMode = () => {
    if (this.state.willEnableGameMode) {
      this.changeGameMode();
    }
  };

  initSound = () => {
    if (this.state.clickCount === 0 && this.state.forceSoundEnabled) {
      this.changeSoundState(true);
    }
  };

  hideDockButton = () => {
    if (this.state.displayDockButton) {
      this.setDisplayDockButton(false);
      document.removeEventListener("click", this.hideDockButton);
      document.removeEventListener("contextmenu", this.hideDockButton);
    }
  };

  initializeSocket = () => {
    const {
      connection_uri: connectionUri,
      password,
      os,
      restart_application: restartApplication,
      resolution,
    } = this.props.workstations.getAppStreamingSessionCTX.data?.attributes || {};

    if (this.websocketConnection && this.websocketConnection.websocket) {
      // Closing socket will restart it automatically.
      Logger.log("Close socket");
      this.websocketConnection.closeSockets();
      this.websocketConnection.restartConnection();
    } else {
      Logger.log("Create web socket connection");
      const { launchFlags } = this.state;
      this.websocketConnection =
        os === OS.windows
          ? new WebsocketConnection({
              password,
              controlWorkstationStatus: this.controlStreamingStatus,
              showNotification: this.showToastNotification,
              autoStopEnabled: false,
              autoStopThreshold: 0,
              setConnectingState: this.setConnectingState,
              onInitConnection: this.onInitConnection,
              onConnection: this.onConnection,
              onDownload: this.onDownload,
              onDownloadFailed: this.onDownloadFailed,
              customResolution: RESOLUTIONS[resolution],
              setIframeSrc: this.setIframeSrc,
              endSession: this.endSession,
              onDuplicateTab: this.onDuplicateTab,
              streamType: STREAM_TYPE.application,
              machineDetails: this.props.workstations.getAppStreamingSessionCTX.data,
              launchFlags,
              changeCursorPosition: this.changeCursorPosition,
              setCursorImage: this.setCursorImage,
              onWebRTCConnectionFailure: this.onWebRTCConnectionFailure,
              setTempFiles: this.setTempFiles,
              onConnectionClosed: this.onConnectionClosed,
              onTempFileDownloadCompleted: this.onTempFileDownloadCompleted,
              setShowKeyboardButton: this.setShowKeyboardButton,
              enableGameModeFromSDK: this.enableGameModeFromSDK,
              disableGameModeFromSDK: this.disableGameModeFromSDK,
              onResetIdleTracker: this.onResetIdleTracker,
              submitStreamPreference: this.submitStreamPreference,
              getSessionMetrics: this.getSessionMetrics,
              onShutdown: this.onShutdown,
              restartApplication,
            })
          : new LinuxWebsocketConnection({
              wsUri: connectionUri,
              password,
              controlWorkstationStatus: this.controlStreamingStatus,
              showNotification: this.showToastNotification,
              autoStopEnabled: false,
              autoStopThreshold: 0,
              setConnectingState: this.setConnectingState,
              onConnection: this.onConnection,
              onInitConnection: this.onInitConnection,
              onDownload: this.onDownload,
              customResolution: RESOLUTIONS[resolution],
              setIframeSrc: this.setIframeSrc,
              endSession: this.endSession,
              onDuplicateTab: this.onDuplicateTab,
              streamType: STREAM_TYPE.application,
              machineDetails: this.props.workstations.getAppStreamingSessionCTX.data,
              launchFlags,
              changeCursorPosition: this.changeCursorPosition,
              setCursorImage: this.setCursorImage,
              onWebRTCConnectionFailure: this.onWebRTCConnectionFailure,
            });
    }
  };

  setSessionMetrics = async (region) => {
    const { os } = getClientInfo();
    const deviceType = getDeviceType();
    const ping = await pingRegion(region);
    const metricsAPI = apiGenerator("post");

    const metricsData = {
      machine_uid: this.props.workstations.getAppStreamingSessionCTX.data?.id,
      os,
      device_type: deviceType,
      ping,
      local_identifier: this.websocketConnection?.appSessionId(),
      connection_type: this.websocketConnection?.currentPlayer,
    };

    this.setState({
      metrics: metricsData,
    });

    metricsAPI(API_ENDPOINTS.APP_STREAMING_SESSION_METRICS, metricsData).catch(() => {}); // do nothing on error
  };

  getSessionMetrics = () => {
    const { metrics } = this.state;
    return metrics;
  };

  changeCursorPosition = (x, y) => {
    this.setState({ cursorX: x, cursorY: y });
  };

  setCursorImage = (img) => {
    this.setState({ cursorImage: img });
  };

  showToastNotification = (props) => {
    const { header, description, autoClose } = props;
    const options = {};
    if (autoClose) options.autoClose = autoClose;
    toast.dismiss();
    toast(<ToastNotificationContent header={header} description={description} />, options);
  };

  setPollingInterval = (intervalTime = POLLING_INTERVAL) => {
    clearInterval(this.pollingInterval);
    this.pollingInterval = setInterval(this.controlStreamingStatus, intervalTime);
  };

  resizeWindow = () => {
    Tracker.time({ type: "connect", start: true });
    this.setState(
      {
        connected: false,
      },
      () => {
        this.websocketConnection.closeSockets();
      },
    );
  };

  controlStreamingStatus = () => {
    const { match, getAppStreamingSessionAPI } = this.props;
    getAppStreamingSessionAPI(match.params.uri);
  };

  showScreenKeyboard = () => {
    this.websocketConnection.sendShowScreenKeyboardEvent();
  };

  toggleFullscreen = () => {
    if (
      document.fullscreenElement /* Standard syntax */ ||
      document.webkitFullscreenElement /* Chrome, Safari and Opera syntax */ ||
      document.mozFullScreenElement /* Firefox syntax */ ||
      document.msFullscreenElement /* IE/Edge syntax */
    ) {
      this.exitFullScreen();
    } else {
      this.enterFullScreen();
    }
  };

  // enterFullScreen
  enterFullScreen = () => {
    const docElement = document.documentElement;
    // Toggle fullscreen if not already in fullscreen
    if (docElement.requestFullScreen) {
      docElement.requestFullScreen();
    } else if (docElement.mozRequestFullScreen) {
      docElement.mozRequestFullScreen();
    } else if (docElement.webkitRequestFullScreen) {
      docElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (docElement.msRequestFullscreen) {
      docElement.msRequestFullscreen();
    }
  };

  /* Close fullscreen */
  exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  };

  enterPassword = () => {
    this.websocketConnection.sendEnterPasswordEvent();
  };

  onConnection = () => {
    this.setState({ connected: true });
    const { micEnabled } = this.state;
    if (micEnabled) this.toggleMic(true);
    this.setAudioMetrics();
    SDKInternal.setConnected(true);
    SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onConnected);
  };

  onInitConnection = () => {
    this.setConnectingState();
  };

  setConnectingState = () => {
    //
  };

  setDisableKeyboardActions = (disableKeyboardActions) => {
    if (this.websocketConnection) {
      this.websocketConnection.setDisableKeyboardActions(disableKeyboardActions);
    }
  };

  setShowKeyboardShortcuts = (showKeyboardShortcuts) => {
    this.setState({ showKeyboardShortcuts });
  };

  setGameModeEnabled = (gameModeEnabled) => {
    this.setState({ gameModeEnabled });
  };

  setSoundEnabled = (soundEnabled) => {
    this.setState({ soundEnabled });
  };

  setChangeableSound = (changeableSound) => {
    this.setState({ changeableSound });
  };

  setChangeableKeyMapping = (changeableKeyMapping) => {
    this.setState({ changeableKeyMapping });
  };

  setQuality = (quality) => {
    this.setState({ quality });
  };

  setDisplayDockButton = (displayDockButton) => {
    this.setState({ displayDockButton });
  };

  setShowClipboardModal = (flag, copyMode) => {
    const { connected } = this.state;
    if (connected) {
      this.setState({
        showClipboardModal: flag,
        clipboardModalCopyMode: copyMode,
      });
    }
  };

  setIsDockOpen = (isDockOpen) => {
    this.setState({ isDockOpen });
  };

  setIframeSrc = (iframeSrc) => {
    this.setState({ iframeSrc });
  };

  setShowConnectionModal = (showConnectionModal) => {
    this.setState({ showConnectionModal });
  };

  setClickCount = (clickCount) => {
    this.setState({ clickCount });
  };

  setMaxSessionDuration = (maxSessionDuration) => {
    this.setState({ maxSessionDuration });
  };

  setSessionExpireTimeout = (sessionExpireTimeout) => {
    this.setState({ sessionExpireTimeout });
  };

  setClickToStart = (clickToStart) => {
    this.setState({ clickToStart });
  };

  setInitialGameMode = (initialGameMode) => {
    this.setState({ initialGameMode });
  };

  setIsFilesDockOpen = (isFilesDockOpen) => {
    this.setState({ isFilesDockOpen });
  };

  setTempFiles = (tempFiles) => {
    this.setState({ tempFiles });
  };

  setShowDownloadModal = (showDownloadModal) => {
    this.setState({ showDownloadModal });
  };

  setDownloadFailed = (downloadFailed) => {
    this.setState({ downloadFailed });
  };

  setShowKeyboardButton = (showKeyboardButton) => {
    this.setState({ showKeyboardButton });
  };

  endSession = () => {
    this.sendParentMessageAndRedirect(ERRORS.SESSION_EXPIRED);
  };

  firefoxSafariOnPaste = (event) => {
    event.preventDefault();
    this.websocketConnection.pasteTextToRemote(event.clipboardData.getData("text"));
    this.setState({ showClipboardModal: false });
  };

  firefoxSafariOnCopy = (event) => {
    event.preventDefault();
    // TODO check this function
    this.websocketConnection.pasteTextToRemote(
      event.clipboardData.setData("text/plain", sessionStorage.getItem("clipboardData")),
    );
    this.setState({ showClipboardModal: false });
  };

  changeSoundState = (state) => {
    this.setSoundEnabled(state);
    saveItemToSessionStorage(SESSION_STORAGE.soundEnabled, state);
    this.websocketConnection.audioStateChanged();
    if (state) {
      this.setAudioMetrics();
    }
  };

  toggleMic = (state) => {
    saveItemToSessionStorage(SESSION_STORAGE.micEnabled, state);
    this.websocketConnection.changeMicrophoneState(state);
    this.setState({ micEnabled: state });
    if (state) {
      this.setAudioMetrics();
    }
  };

  applyStreamingPreferences = (options) => {
    // TODO: handle cpu instances
    const workstationType = "GPU";
    applyAdvancedStreamingSettings(workstationType, options);
  };

  submitStreamPreference = (options) => {
    this.applyStreamingPreferences(options);

    this.websocketConnection.closeSockets();
    this.websocketConnection.restartConnection();
  };

  changeVerticalScrollDirection = (inverseDirection) => {
    applyScrollDirectionPreference(inverseDirection);
    this.websocketConnection.changeScrollPreference();
  };

  changeSwitchCmdPreference = (switchCmdPreference) => {
    this.websocketConnection.changeCmdPreference(switchCmdPreference);
  };

  onPointerLockChanged = () => {
    if (document.pointerLockElement === null) {
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onPointerLockDisabled);
      this.disableGameMode();
    } else {
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onPointerLockEnabled);
      this.setState({
        gameModeEnabled: true,
      });
    }
  };

  changeGameMode = () => {
    const { gameModeEnabled } = this.state;
    if (gameModeEnabled) {
      this.disableGameMode();
    } else {
      this.enableGameMode();
    }
  };

  enableGameMode = (e) => {
    this.websocketConnection.changeGameMode(true);

    if (e) {
      this.changeCursorPosition(e.clientX, e.clientY);
    }
  };

  disableGameMode = () => {
    const { translate } = this.props;

    const { gameModeDisabledNotificationShown } = this.state;

    this.websocketConnection.changeGameMode(false);

    this.setState({
      gameModeEnabled: false,
    });

    if (!gameModeDisabledNotificationShown) {
      setTimeout(() => {
        this.showToastNotification({
          header: translate("360viewMode.disabled.header"),
          description: translate("360viewMode.disabled.description"),
        });
        this.setState({ gameModeDisabledNotificationShown: true });
      }, 10);
    }
  };

  handlePointerLockError = () => {
    setTimeout(() => {
      this.enableGameMode();
    }, 1000);
  };

  setGameModePollingInterval = (intervalTime = 2000) => {
    clearInterval(this.gameModePollingInterval);
    this.gameModePollingInterval = setInterval(this.checkGameModeStatus, intervalTime);
  };

  checkGameModeStatus = (e) => {
    const { initialGameMode, gameModeEnabled, gameModeDisabledFromSDK, clickToStart } = this.state;
    if (initialGameMode && !gameModeEnabled && !gameModeDisabledFromSDK && !clickToStart) {
      this.enableGameMode(e);
    }
  };

  enableGameModeFromSDK = () => {
    this.setState({
      gameModeDisabledFromSDK: false,
    });
    this.enableGameMode();
  };

  disableGameModeFromSDK = () => {
    this.setState({
      gameModeDisabledFromSDK: true,
    });
    this.disableGameMode();
  };

  onDuplicateTab = () => {
    const { history } = this.props;
    const { enterprise } = this.state;
    if (enterprise) {
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onFailed);
    }
    const message = JSON.stringify({ error: ERRORS.DUPLICATE_TAB });
    window.parent.postMessage(message, "*");
    setTimeout(() => {
      history.push(routes.workstationDuplicate);
    }, 3000);
  };

  onWebRTCConnectionFailure = () => {
    const { history, match } = this.props;
    this.closeSoundProcess();
    const message = JSON.stringify({ error: ERRORS.CONNECTION_FAILED });
    window.parent.postMessage(message, "*");
    setTimeout(() => {
      // this.props.history.push(routes.workstationConnectionFailed);
      history.push({
        pathname: routes.streamConnectionFailed,
        state: { overrideRetryRoute: match.url, overrideDashboardRoute: match.url },
      });
    }, 3000);
  };

  onConnectionClosed = () => {
    const { connected } = this.state;
    if (connected) {
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onDisconnected);
      SDKInternal.setConnected(false);
    }
    this.setState({ connected: false });
  };

  closeSoundProcess = () => {
    this.changeSoundState(false);
    const { isMicEnabled } = this.state;
    if (isMicEnabled) {
      this.toggleMic();
    }
  };

  handlePlayButtonClick = () => {
    const { workstations } = this.props;
    const { initialGameMode, soundEnabled } = this.state;
    const { sound, microphone } = workstations.getAppStreamingSessionCTX.data?.attributes;

    if (microphone === SOUND_AND_MICROPHONE_OPTIONS.activate_on_start) {
      this.toggleMic(true);
    }

    this.setClickToStart(false);
    document.children[0].focus();

    if (sound === SOUND_AND_MICROPHONE_OPTIONS.activate_on_start || soundEnabled) {
      this.changeSoundState(true);
    }

    if (
      microphone === SOUND_AND_MICROPHONE_OPTIONS.activate_on_start ||
      getItemFromSessionStorage(SESSION_STORAGE.micEnabled)
    ) {
      this.toggleMic(true);
    }

    if (initialGameMode) {
      this.enableGameMode();
    }
    this.setAudioMetrics();
  };

  showJoystick = (joystick) => {
    switch (joystick) {
      case JOYSTICK_OPTIONS.all_devices:
        return true;
      case JOYSTICK_OPTIONS.mobile_and_tablet:
        return isMobile || isTablet || isIPad;
      default:
        return false;
    }
  };

  onDownload = (file) => {
    if (file) {
      const { downloadFileAPISuccess } = this.props;
      downloadFileAPISuccess(file);
      this.setState({
        showDownloadModal: true,
      });
    }
  };

  onDownloadFailed = () => {
    this.setState({
      downloadFailed: true,
    });
  };

  setDisableKeyboardActions = (disableKeyboardActions) => {
    if (this.websocketConnection) {
      this.websocketConnection.setDisableKeyboardActions(disableKeyboardActions);
    }
  };

  reloadTempFiles = () => {
    this.websocketConnection.sendListTempFilesEvent();
  };

  onTempFileDownloadCompleted = () => {
    const { clearUploadedFiles } = this.props;
    this.reloadTempFiles();
    clearUploadedFiles();
  };

  onOrientationChange = () => {
    const { workstations } = this.props;
    const { resolution } = workstations.getAppStreamingSessionCTX.data?.attributes || {};
    if (resolution !== "res_scale") {
      return;
    }
    if (this.websocketConnection && this.websocketConnection.websocket.readyState === WebSocket.OPEN) {
      this.resizeWindow();
    }
  };

  handleResize = () => {
    const { workstations } = this.props;
    const { resolution } = workstations.getAppStreamingSessionCTX.data?.attributes || {};
    if (resolution !== "res_scale") {
      return;
    }
    if (this.websocketConnection && this.websocketConnection.websocket.readyState === WebSocket.OPEN) {
      this.resizeWindow();
    }
  };

  setAudioMetrics = () => {
    let audioInputDevice;
    let audioOutputDevice;

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return;
    }

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const audioOutputDevices = devices.filter((device) => device.kind === "audiooutput");
        const audioInputDevices = devices.filter((device) => device.kind === "audioinput");

        if (audioOutputDevices.length > 0) {
          [audioOutputDevice] = audioOutputDevices;
        }

        if (audioInputDevices.length > 0) {
          [audioInputDevice] = audioInputDevices;
        }
      })
      .catch(() => {
        // do nothing on error
      })
      .finally(() => {
        try {
          const metricsAPI = apiGenerator("post");
          const { workstations } = this.props;
          metricsAPI(API_ENDPOINTS.APP_STREAMING_SESSION_METRICS, {
            machine_uid: workstations.getAppStreamingSessionCTX.data?.id,
            video_element_muted: this.websocketConnection.videoElement.muted,
            audio_track_enabled: this.websocketConnection.videoElement.srcObject.getAudioTracks()[0].enabled,
            audio_input: audioInputDevice,
            audio_output: audioOutputDevice,
            local_identifier: this.websocketConnection?.appSessionId(),
          }).catch(() => {});
        } catch (error) {
          // do nothing on error
        }
      });
  };

  onIdleTimeEnd = () => {
    const { enterprise } = this.state;
    if (enterprise) {
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onInactive);
    }

    this.sendParentMessageAndRedirect(ERRORS.SESSION_IDLE);
  };

  onWarningTimeEnd = () => {
    const { translate } = this.props;
    this.showToastNotification({
      description: translate("sessionIdleMessage"),
    });
  };

  handleOpenTaskManagerShortcut = () => {
    // cmd + shift + esc event emulation
    const event = new KeyboardEvent("keydown", {
      key: "Escape",
      code: "Escape",
      keyCode: 27,
      shiftKey: true,
      metaKey: true,
      ctrlKey: true,
    });
    document.dispatchEvent(event);
  };

  onResetIdleTracker = () => {
    const { resetIdleTracker } = this.state;
    this.setState({
      resetIdleTracker: !resetIdleTracker,
    });
  };

  onShutdown = () => {
    const { enterprise } = this.state;

    if (enterprise) {
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onFailed);
    }
    this.sendParentMessageAndRedirect(ERRORS.STREAM_NOT_FOUND);
  };

  sendParentMessageAndRedirect = (error) => {
    const { history } = this.props;
    const { streamID, connected } = this.state;

    if (connected) {
      SDKInternal.setConnected(false);
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onDisconnected);
    }

    const message = JSON.stringify({ error });
    window.parent.postMessage(message, "*");

    setTimeout(() => {
      history.push({
        pathname: routes.appStreamingError,
        streamID,
        error,
      });
    }, 1000);
  };

  render() {
    const {
      connected,
      gameModeEnabled,
      changeableKeyMapping,
      soundEnabled,
      changeableSound,
      micEnabled,
      showKeyboardShortcuts,
      quality,
      changeableQuality,
      showClipboardModal,
      clipboardModalCopyMode,
      showConnectionModal,
      iframeSrc,
      isDockOpen,
      displayDockButton,
      cursorX,
      cursorY,
      cursorImage,
      dockVisibility,
      dockColor,
      clickToStart,
      isFilesDockOpen,
      tempFiles,
      showDownloadModal,
      downloadFailed,
      showKeyboardButton,
      resetIdleTracker,
    } = this.state;
    const { workstations, translate, files: filesCTX } = this.props;
    const {
      friendly_status: friendlyStatus,
      application,
      dark_mode: darkMode,
      files_enabled: filesEnabled,
      texts,
      os,
      joystick,
      dock_position: dockPosition,
      idle_duration: idleDuration,
      microphone,
      show_play_button: showPlayButton,
    } = workstations.getAppStreamingSessionCTX.data?.attributes || {};

    const { banner_url: bannerURL, logo_url: logoURL, enterprise, name } = application?.attributes || {};
    return (
      <div className="workstation-container">
        <Hotkey
          hotkey="ctrl+g"
          callback={() => {
            return gameModeEnabled ? this.disableGameMode() : this.enableGameMode();
          }}
        />
        <Hotkey hotkey="ctrl+shift+p+o+l" callback={this.handleOpenTaskManagerShortcut} />
        <WorkstationCanvas
          appStreaming
          isConnected={connected}
          translate={translate}
          showPlayButton={clickToStart && showPlayButton}
          white
          customLoader={
            <>
              {application && (
                <div className="loading-container">
                  <AppStreamingLoader
                    status={friendlyStatus}
                    texts={texts}
                    dark={darkMode}
                    enterprise={enterprise}
                    bannerURL={bannerURL}
                    logoURL={logoURL}
                    translate={translate}
                    appName={name}
                    connected={connected}
                    showPlayButton={clickToStart && showPlayButton}
                    microphone={microphone}
                    handlePlayButtonClick={this.handlePlayButtonClick}
                  />
                </div>
              )}
            </>
          }
        />
        {showKeyboardShortcuts && (
          <KeyboardShortcuts
            websocketConnection={this.websocketConnection}
            setShowKeyboardShortcuts={this.setShowKeyboardShortcuts}
          />
        )}
        <ToastNotificationContainer />
        {connected && showClipboardModal && (
          <SyncClipboardModal
            setShowClipboardModal={this.setShowClipboardModal}
            clipboardModalCopyMode={clipboardModalCopyMode}
            firefoxSafariOnPaste={this.firefoxSafariOnPaste}
            firefoxSafariOnCopy={this.firefoxSafariOnCopy}
            translate={translate}
          />
        )}
        <WorkstationCursor visible={gameModeEnabled} x={cursorX} y={cursorY} cursorImage={cursorImage} os={os} />
        {connected && showConnectionModal && (
          <Modal
            headerText={translate("connectionModal.header")}
            buttonText={translate("connectionModal.buttonText")}
            descriptionText={translate("connectionModal.description")}
            buttonAction={() => {
              this.setShowConnectionModal(false);
            }}
            closeAction={() => {
              this.setShowConnectionModal(false);
            }}
            closeOnOverlayClick
            small
            centered
          />
        )}
        {iframeSrc && <IframeModal setShowIframeModal={this.setIframeSrc} src={iframeSrc} />}
        {connected && !isMobile && dockPosition !== DOCK_POSITIONS.hide && (
          <AppStreamingDock
            isDockOpen={isDockOpen}
            displayDockButton={displayDockButton}
            setIsDockOpen={this.setIsDockOpen}
            translate={translate}
            soundEnabled={soundEnabled}
            changeableSound={changeableSound}
            dockVisibility={dockVisibility}
            dockColor={dockColor}
            changeSoundState={this.changeSoundState}
            quality={quality}
            setQuality={this.setQuality}
            changeableQuality={changeableQuality}
            toggleFullscreen={this.toggleFullscreen}
            isMicEnabled={micEnabled}
            toggleMic={this.toggleMic}
            gameModeEnabled={gameModeEnabled}
            changeableKeyMapping={changeableKeyMapping}
            changeGameMode={this.changeGameMode}
            submitStreamPreference={this.submitStreamPreference}
            filesEnabled={filesEnabled}
            isFilesDockOpen={isFilesDockOpen}
            setIsFilesDockOpen={this.setIsFilesDockOpen}
            connected={connected}
            dockPosition={dockPosition}
            clickToStart={clickToStart}
          />
        )}
        {showKeyboardButton && isMobile && <KeyboardButton />}
        {connected && !isMobile && filesEnabled && (
          <>
            <TempFileUploadOverlay
              translate={translate}
              onFileDrop={(files) => {
                const { uploadStreamSessionFile } = this.props;
                this.setIsFilesDockOpen(true);
                uploadStreamSessionFile({
                  files,
                  uid: workstations.getAppStreamingSessionCTX.data?.id,
                });
              }}
            />
            <FilesDock
              files={tempFiles}
              isFilesDockOpen={isFilesDockOpen}
              setIsFilesDockOpen={this.setIsFilesDockOpen}
              reloadTempFiles={this.reloadTempFiles}
              sendListTempFilesEvent={this.websocketConnection.sendListTempFilesEvent}
              sendDownloadFileEvent={this.websocketConnection.sendDownloadFileEvent}
              sendDownloadTempFileEvent={this.websocketConnection.sendDownloadTempFileEvent}
              setDisableKeyboardActions={this.setDisableKeyboardActions}
              openVagonExplorer={this.websocketConnection.openVagonExplorer}
              clickToStart={clickToStart}
              translate={translate}
            />
          </>
        )}
        {connected && showDownloadModal && filesEnabled && (
          <FileDownloadModal
            fileName={filesCTX.downloadFileCTX.name}
            fileSize={filesCTX.downloadFileCTX.size}
            downloadUrl={filesCTX.downloadFileCTX.url}
            setShowDownloadModal={this.setShowDownloadModal}
            translate={translate}
            afterLinkClick={() => {
              this.setShowDownloadModal(false);
            }}
          />
        )}
        {downloadFailed && (
          <Modal
            headerText={translate("filesDock.downloadErrors.downloadLimit.header")}
            descriptionText={translate("filesDock.downloadErrors.downloadLimit.description")}
            buttonText={translate("filesDock.downloadErrors.downloadLimit.buttonText")}
            buttonAction={() => {
              this.setDownloadFailed(false);
            }}
            closeAction={() => {
              this.setDownloadFailed(false);
            }}
            topRightIcon="close"
            topRightIconAction={() => this.setDownloadFailed(false)}
            closeOnOverlayClick
            small
          />
        )}
        {connected && !clickToStart && this.showJoystick(joystick) && (
          <Joystick sendPayload={this.websocketConnection.sendMessageToSocket} />
        )}
        {connected && idleDuration > 0 && (
          <IdleTracker
            idleDuration={idleDuration}
            onIdleTimeEnd={this.onIdleTimeEnd}
            warningTimeOffset={IDLE_TIME_WARNING_OFFSET}
            onWarningTimeEnd={this.onWarningTimeEnd}
            reset={resetIdleTracker}
          />
        )}
        <FirstClickHandlerWrapper
          enabled={connected && clickToStart && !showPlayButton}
          onFirstClick={this.handlePlayButtonClick}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  workstations: state.workstations,
  files: state.files,
});

const actionCreators = {
  getAppStreamingSessionAPI,
  uploadStreamSessionFile,
  downloadFileAPISuccess,
  clearUploadedFiles,
  resetAppStreamingSessionCtx,
};

export default connect(mapStateToProps, actionCreators)(AppStreamingContainer);
